import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import AdminPanel from "./AdminPanel";
import Navbar from "./Navbar";
import SponsorsPage from "./SponsorsPage";
import RulesPage from "./RulesPage";
const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="appRoutes">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/adminpanel" element={<AdminPanel />} />
          <Route path="/sponsors" element={<SponsorsPage />} />
          <Route path="/rules" element={<RulesPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default RoutesApp;
