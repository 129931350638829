import React from "react";
import Sponsors from "./Sponsors";
const SponsorsPage = () => {
  return (
    <div className="sponsorsPage">
      <Sponsors></Sponsors>
    </div>
  );
};

export default SponsorsPage;
