import "./App.css";
import { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import { getDatabase, push, set } from "firebase/database";
import { ref as reference } from "firebase/database";

import { useNavigate } from "react-router-dom";

function AdminPanel() {
  const navigate = useNavigate();
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUrls, setFileUrls] = useState([]);
  const [fileAnswersUrls, setFileAnswersUrls] = useState([]);

  const filesListRef = ref(storage, "excels/");

  const [countdownDate, setCountdownDate] = useState("");
  const [state, setState] = useState("booklet");

  const getStateDisplayText = (state) => {
    switch (state) {
      case "booklet":
        return "Booklet";
      case "treasures":
        return "Treasures";
      case "onlineChallenges":
        return "Online Challenges";
      case "quests":
        return "Quests";
      default:
        return "";
    }
  };

  const handleUpload = () => {
    if (fileUpload == null) return;
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Beirut",
    });

    // Replace spaces and commas with underscores to create a valid filename
    const formattedDate = currentDate
      .replaceAll("/", "-")
      .replaceAll(" ", "")
      .replaceAll(",", "-");
    const uploadPath = `excels/${state}/${formattedDate}-${fileUpload.name}`;
    const fileRef = ref(storage, uploadPath);
    uploadBytes(fileRef, fileUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        alert("uploaded");
        setFileUrls((prev) => [...prev, url]);
        const db = getDatabase();
        const newDocRef = reference(db, `${state}`);

        set(newDocRef, { latest: formattedDate + "-" + fileUpload.name })
          .then(() => {
            alert("Data saved successfully");
          })
          .catch((error) => {
            alert("Error: " + error.message);
          });
      });
    });
  };

  const filesListAnswersRef = ref(storage, "excelsanswers/");
  // Fetching answer files on component mount
  useEffect(() => {
    listAll(filesListAnswersRef).then((response) => {
      response.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          setFileAnswersUrls((prev) => [...prev, { url, name: item.name }]);
        });
      });
    });
  }, []);

  // JSX for downloading answer files
  const downloadAnswerFile = (url) => {
    window.open(url, "_blank");
  };

  const uploadCoutdown = async () => {
    const db = getDatabase();
    const stateIds = {
      booklet: "-NvF9WZlMqFHGNyhGpu3",
      treasures: "-Nv_4H2_gXEG07_TStdY",
      onlineChallenges: "-Nv_4NdcIXbQnPaQnmhu",
      quests: "-Nv_4QGE2keWDRJmsq08",
    };
    const countdownId = stateIds[state];
    const newDocRef = reference(db, `Countdown/${countdownId}`);

    set(newDocRef, { countdownDate: countdownDate })
      .then(() => {
        alert("Data saved successfully");
      })
      .catch((error) => {
        alert("Error: " + error.message);
      });
  };

  console.log(fileUrls);

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if username and password match
    if (username === "admin" && password === "rallypaperaubpass") {
      setLoggedIn(true);
    } else {
      alert("Invalid username or password");
    }
  };
  const handleStateChange = (newState) => {
    setState(newState);
  };

  // State and setter for booklet answers
  const [bookletAnswers, setBookletAnswers] = useState([]);

  // State and setter for treasures answers
  const [treasuresAnswers, setTreasuresAnswers] = useState([]);

  // State and setter for online challenges answers
  const [onlineChallengesAnswers, setOnlineChallengesAnswers] = useState([]);

  // State and setter for quests answers
  const [questsAnswers, setQuestsAnswers] = useState([]);
  // Fetching answer files for booklet
  useEffect(() => {
    const filesListBookletRef = ref(storage, `excelsanswers/booklet/`);
    listAll(filesListBookletRef).then((response) => {
      console.log(response);
      response.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          setBookletAnswers((prev) => [...prev, { url, name: item.name }]);
        });
      });
    });
  }, []);

  // Fetching answer files for treasures
  useEffect(() => {
    const filesListTreasuresRef = ref(storage, `excelsanswers/treasures/`);
    listAll(filesListTreasuresRef).then((response) => {
      response.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          setTreasuresAnswers((prev) => [...prev, { url, name: item.name }]);
        });
      });
    });
  }, []);

  // Fetching answer files for online challenges
  useEffect(() => {
    const filesListOnlineChallengesRef = ref(
      storage,
      `excelsanswers/onlineChallenges/`
    );
    listAll(filesListOnlineChallengesRef).then((response) => {
      response.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          setOnlineChallengesAnswers((prev) => [
            ...prev,
            { url, name: item.name },
          ]);
        });
      });
    });
  }, []);

  // Fetching answer files for quests
  useEffect(() => {
    const filesListQuestsRef = ref(storage, `excelsanswers/quests/`);
    listAll(filesListQuestsRef).then((response) => {
      response.items.forEach((item) => {
        console.log(item);
        getDownloadURL(item).then((url) => {
          setQuestsAnswers((prev) => [...prev, { url, name: item.name }]);
        });
      });
    });
  }, []);

  return (
    <>
      {loggedIn ? (
        <>
          <div className="AdminPanel">
            <div className="stateButtons">
              <button
                className={
                  state === "booklet"
                    ? "stateButton selectedButton"
                    : "stateButton"
                }
                onClick={() => handleStateChange("booklet")}
              >
                Booklet
              </button>
              <button
                className={
                  state === "treasures"
                    ? "stateButton selectedButton"
                    : "stateButton"
                }
                onClick={() => handleStateChange("treasures")}
              >
                Treasures
              </button>
              <button
                className={
                  state === "onlineChallenges"
                    ? "stateButton selectedButton"
                    : "stateButton"
                }
                onClick={() => handleStateChange("onlineChallenges")}
              >
                Online Challenges
              </button>
              <button
                className={
                  state === "quests"
                    ? "stateButton selectedButton"
                    : "stateButton"
                }
                onClick={() => handleStateChange("quests")}
              >
                Quests
              </button>
            </div>
            <div className="countdown">
              {" "}
              <h2>Countdown</h2>
              <input
                type="datetime-local"
                value={countdownDate}
                onChange={(event) => setCountdownDate(event.target.value)}
              />
              <button className="adminPanelButton" onClick={uploadCoutdown}>
                Upload Countdown
              </button>
            </div>
            <h2>Upload {getStateDisplayText(state)}</h2>
            <input
              type="file"
              onChange={(event) => {
                setFileUpload(event.target.files[0]);
              }}
            />
            <button className="adminPanelButton" onClick={handleUpload}>
              Upload {getStateDisplayText(state)}
            </button>

            {/* Displaying uploaded files */}
            <div>
              <h2>Current Booklet</h2>
              {fileUrls[0] && (
                <div>
                  <a href={fileUrls[0]}>{fileUrls[0].name}</a>
                </div>
              )}
            </div>

            {/* Displaying answer files */}
            <div>
              <h2>Answers</h2>
              {/* Render download buttons based on state */}
              {state === "booklet" &&
                bookletAnswers.map((file, index) => (
                  <div key={index} className="answerRow">
                    <span>{file.name}</span>
                    <button
                      className="adminPanelButton"
                      onClick={() => downloadAnswerFile(file.url)}
                    >
                      Download
                    </button>
                  </div>
                ))}
              {state === "treasures" &&
                treasuresAnswers.map((file, index) => (
                  <div key={index} className="answerRow">
                    <span>{file.name}</span>
                    <button
                      className="adminPanelButton"
                      onClick={() => downloadAnswerFile(file.url)}
                    >
                      Download
                    </button>
                  </div>
                ))}
              {state === "onlineChallenges" &&
                onlineChallengesAnswers.map((file, index) => (
                  <div key={index} className="answerRow">
                    <span>{file.name}</span>
                    <button
                      className="adminPanelButton"
                      onClick={() => downloadAnswerFile(file.url)}
                    >
                      Download
                    </button>
                  </div>
                ))}
              {state === "quests" &&
                questsAnswers.map((file, index) => (
                  <div key={index} className="answerRow">
                    <span>{file.name}</span>
                    <button
                      className="adminPanelButton"
                      onClick={() => downloadAnswerFile(file.url)}
                    >
                      Download
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="login">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Username:</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="marginTop">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit">Login</button>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default AdminPanel;
