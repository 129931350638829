import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../src/Logos/suyool.jpg";
import aubLogo from "../src/Logos/rally paper x suyool_page-0001.jpg";
import hamburger from "./hamburger.svg";
import x from "./x.svg";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link
            to="/"
            onClick={() => {
              setIsOpen(false);
            }}
            className="navbarDiv"
          >
            {/* <div className="aubLogo">RALLYPAPER AUB</div> */}
            <img src={aubLogo} alt="Logo" />
            <div className="x">X</div>
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div
          className={isOpen ? "menu-icon menu-icon-x" : "menu-icon"}
          onClick={toggleNavbar}
        >
          <img src={isOpen ? x : hamburger} alt=""></img>
        </div>
      </nav>
      <div className={`navbar-menu ${isOpen ? "open" : ""}`}>
        {/* <div>
          <Link
            to="/about"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            About
          </Link>
        </div> */}
        <div>
          <Link
            to="/sponsors"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Sponsors
          </Link>
        </div>
        <div>
          <Link
            to="/rules"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Rules
          </Link>
        </div>
        {/* <div>
          <Link
            to="/adminpanel"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Team Hub
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default Navbar;
