import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyC5LO1t6STJ9RDIT-rXmoGFlMZP8Pygxig",
  authDomain: "aubdatabase-9ed12.firebaseapp.com",
  databaseURL:
    "https://aubdatabase-9ed12-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aubdatabase-9ed12",
  storageBucket: "aubdatabase-9ed12.appspot.com",
  messagingSenderId: "813556223655",
  appId: "1:813556223655:web:e84f275491f29a09aa1582",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
