// App.js
import "./App.css";
import { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "./firebase";
import { useNavigate } from "react-router-dom";
import { getDatabase, get } from "firebase/database";
import { ref as reference } from "firebase/database";
import Sponsors from "./Sponsors";
function App() {
  const navigate = useNavigate();
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUrls, setFileUrls] = useState([]);
  const [countdownDate, setCountdownDate] = useState("");
  const [timeLeft, setTimeLeft] = useState("");
  const [teamName, setTeamName] = useState("");

  // Function to upload file
  const uploadFile = () => {
    if (fileUpload == null) return;

    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Beirut",
    });

    // Replace spaces and commas with underscores to create a valid filename
    const formattedDate = currentDate
      .replaceAll("/", "-")
      .replaceAll(" ", "")
      .replaceAll(",", "-");
    const fileRef = ref(
      storage,
      `excelsanswers/${state}/${teamName} - ${formattedDate} - ${fileUpload.name}`
    );
    uploadBytes(fileRef, fileUpload).then((snapshot) => {
      alert("File sent");
      getDownloadURL(snapshot.ref).then((url) => {
        // setFileUrls((prev) => [...prev, url]);
      });
    });
  };
  const [bookletData, setBookletData] = useState([]);
  const [treasuresData, setTreasuresData] = useState([]);
  const [onlineChallengesData, setOnlineChallengesData] = useState([]);
  const [questsData, setQuestsData] = useState([]);
  const [bookletDataName, setBookletDataName] = useState();
  const [treasuresDataName, setTreasuresDataName] = useState();
  const [onlineChallengesDataName, setOnlineChallengesDataName] = useState();
  const [questsDataName, setQuestsDataName] = useState();

  const fetchFileData = (type, setData) => {
    const fileListRef = ref(storage, `excels/${type}`);
    listAll(fileListRef).then((response) => {
      console.log(response);
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setData((prev) => [...prev, { url, name: item.name }]);
        });
      });
    });
  };
  useEffect(() => {
    fetchFileData("booklet", setBookletData);
    fetchFileData("treasures", setTreasuresData);
    fetchFileData("onlineChallenges", setOnlineChallengesData);
    fetchFileData("quests", setQuestsData);
  }, []);

  useEffect(() => {
    console.log("data");
    // console.log(bookletData);
    // console.log(treasuresData);
    // console.log(onlineChallengesData);
    // console.log(questsData);
    console.log(bookletDataName);
    console.log(treasuresDataName);
    console.log(onlineChallengesDataName);
    console.log(questsDataName);
  }, [
    bookletDataName,
    onlineChallengesDataName,
    questsDataName,
    treasuresDataName,
  ]);
  const [loggedIn, setLoggedIn] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    // Array of accounts
    const accounts = [
      { username: "OSBET", password: "charbel@648" },
      { username: "TNIORS", password: "ghatts0802" },
      { username: "AUBLITZ", password: "fas@382" },
      { username: "SOPHOMAANA", password: "hummus$22" },
      { username: "XTR3M", password: "fatouch&26" },
      { username: "E2THEWIN", password: "BOSTON739" },
    ];

    // Check if username and password match any account
    const account = accounts.find(
      (acc) => acc.username === username && acc.password === password
    );

    if (account) {
      setTeamName(account.username);
      setLoggedIn(true);
    } else {
      alert("Invalid username or password");
    }
  };

  // Function to calculate time left until countdown date
  useEffect(() => {
    if (countdownDate) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = new Date(countdownDate) - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const padWithZero = (num) => {
          return num < 10 ? "0" + num : num;
        };
        setTimeLeft(
          `${days > 0 ? days + " days, " : ""}${padWithZero(
            hours
          )}:${padWithZero(minutes)}:${padWithZero(seconds)}`
        );

        if (distance < 0) {
          clearInterval(interval);
          setTimeLeft("Countdown expired");
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdownDate]);
  const [bookletCountdownDate, setBookletCountdownDate] = useState("");
  const [treasuresCountdownDate, setTreasuresCountdownDate] = useState("");
  const [onlineChallengesCountdownDate, setOnlineChallengesCountdownDate] =
    useState("");
  const [questsCountdownDate, setQuestsCountdownDate] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase(); // Assuming `app` is already initialized elsewhere
      const dbRef = reference(db);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setBookletCountdownDate(
          snapshot.val().Countdown[Object.keys(snapshot.val().Countdown)[0]]
            .countdownDate
        );
        setTreasuresCountdownDate(
          snapshot.val().Countdown[Object.keys(snapshot.val().Countdown)[1]]
            .countdownDate
        );
        setOnlineChallengesCountdownDate(
          snapshot.val().Countdown[Object.keys(snapshot.val().Countdown)[2]]
            .countdownDate
        );
        setQuestsCountdownDate(
          snapshot.val().Countdown[Object.keys(snapshot.val().Countdown)[3]]
            .countdownDate
        );
        setCountdownDate(
          snapshot.val().Countdown[Object.keys(snapshot.val().Countdown)[0]]
            .countdownDate
        );
        setBookletDataName(snapshot.val().booklet.latest);
        setOnlineChallengesDataName(snapshot.val().onlineChallenges.latest);
        setTreasuresDataName(snapshot.val().treasures.latest);
        setQuestsDataName(snapshot.val().quests.latest);
      } else {
        alert("Error fetching data");
      }
    };

    fetchData(); // Fetch data when the component mounts
  }, []);

  const [state, setState] = useState("booklet");
  const handleStateChange = (newState) => {
    setState(newState);
  };

  useEffect(() => {
    // Map state to corresponding countdown date state
    const countdownDateStates = {
      booklet: bookletCountdownDate,
      treasures: treasuresCountdownDate,
      onlineChallenges: onlineChallengesCountdownDate,
      quests: questsCountdownDate,
    };

    // Update countdownDate state based on the current state
    setCountdownDate(countdownDateStates[state]);
  }, [
    state,
    bookletCountdownDate,
    treasuresCountdownDate,
    onlineChallengesCountdownDate,
    questsCountdownDate,
  ]);

  const getStateDisplayText = (state) => {
    switch (state) {
      case "booklet":
        return "Booklet";
      case "treasures":
        return "Treasures";
      case "onlineChallenges":
        return "Online Challenges";
      case "quests":
        return "Quests";
      default:
        return "";
    }
  };
  const downloadAnswerFile = (url) => {
    window.open(url, "_blank");
  };
  if (loggedIn === false) {
    return (
      <div className="login">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="marginTop">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="stateButtons">
        <button
          className={
            state === "booklet" ? "stateButton selectedButton" : "stateButton"
          }
          onClick={() => handleStateChange("booklet")}
        >
          Booklet
        </button>
        <button
          className={
            state === "treasures" ? "stateButton selectedButton" : "stateButton"
          }
          onClick={() => handleStateChange("treasures")}
        >
          Treasures
        </button>
        <button
          className={
            state === "onlineChallenges"
              ? "stateButton selectedButton"
              : "stateButton"
          }
          onClick={() => handleStateChange("onlineChallenges")}
        >
          Online Challenges
        </button>
        <button
          className={
            state === "quests" ? "stateButton selectedButton" : "stateButton"
          }
          onClick={() => handleStateChange("quests")}
        >
          Quests
        </button>
      </div>
      <div className="countdown">
        <div className="countdownTimer">{timeLeft}</div>
      </div>
      {/* {fileUrls[0] && (
        <a href={fileUrls[0]}>Download {getStateDisplayText(state)}</a>
      )} */}
      {getStateDisplayText(state) && (
        <>
          {state === "booklet" && bookletData[0] && (
            <>
              {console.log(bookletData)}
              {bookletData.find((item) => item.name === bookletDataName) && (
                <button
                  onClick={() =>
                    downloadAnswerFile(
                      bookletData.find((item) => item.name === bookletDataName)
                        .url
                    )
                  }
                >
                  Download {getStateDisplayText(state)}
                </button>
              )}
            </>
          )}
          {state === "treasures" && treasuresData[0] && (
            <>
              {treasuresData.find(
                (item) => item.name === treasuresDataName
              ) && (
                <button
                  onClick={() =>
                    downloadAnswerFile(
                      treasuresData.find(
                        (item) => item.name === treasuresDataName
                      ).url
                    )
                  }
                >
                  Download {getStateDisplayText(state)}
                </button>
              )}
            </>
          )}
          {state === "onlineChallenges" && onlineChallengesData[0] && (
            <>
              {onlineChallengesData.find(
                (item) => item.name === onlineChallengesDataName
              ) && (
                <button
                  onClick={() =>
                    downloadAnswerFile(
                      onlineChallengesData.find(
                        (item) => item.name === onlineChallengesDataName
                      ).url
                    )
                  }
                >
                  Download {getStateDisplayText(state)}
                </button>
              )}
            </>
          )}
          {state === "quests" && questsData[0] && (
            <>
              {questsData.find((item) => item.name === questsDataName) && (
                <button
                  onClick={() =>
                    downloadAnswerFile(
                      questsData.find((item) => item.name === questsDataName)
                        .url
                    )
                  }
                >
                  Download {getStateDisplayText(state)}
                </button>
              )}
            </>
          )}
        </>
      )}

      <h2>Upload {getStateDisplayText(state)}</h2>

      <input
        type="file"
        onChange={(event) => {
          setFileUpload(event.target.files[0]);
        }}
      />
      {teamName !== "" && fileUpload !== (null || undefined) && (
        <button onClick={uploadFile}>
          Upload {getStateDisplayText(state)}
        </button>
      )}

      <Sponsors></Sponsors>
    </div>
  );
}

export default App;
