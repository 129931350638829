import React, { useState } from "react";

const RulesPage = () => {
  const rules = [
    {
      title: "General Instructions",
      desc: (
        <ul>
          <li>
            Any act of disrespect will result in disqualification.
            <br /> <br />
          </li>
          <li>
            All submissions should be made on time; otherwise, they will be
            disqualified.
            <br /> <br />
          </li>
          <li>
            Please only use phone calls for communication; WhatsApp messages
            will not be accepted.
            <br /> <br />
          </li>
          <li>
            Some of the online challenges may require the organizing team to be
            physically present with you. Therefore, the timing may not always be
            crucial, as the presence of the organizing team is what matters.
            <br /> <br />
          </li>
          <li>
            Non-AUB students are not permitted to enter the AUB campus. They may
            only access the gate of the orange building, which is not connected
            to the rest of AUB. All submissions should be at the specified gate.
            <br /> <br />
          </li>
          <li>
            The contact person's number will be provided in each booklet. Please
            do not contact other members of the organizing team, as they will
            not respond.
            <br /> <br />
          </li>
          <li>
            Cheating is strictly prohibited; we are very serious about it.
            <br /> <br />
          </li>
          <li>
            Certain challenges and online challenges may require a small sum of
            money.
            <br /> <br />
          </li>
        </ul>
      ),
    },
    {
      title: "Online Challenges Instructions",
      desc: (
        <ul>
          It's happening – AUB Rally Paper's hardest online challenges are back!
          <br />
          <br />
          <li>
            All Day Means till 8:00PM on the first day (Saturday) and 5:00PM on
            the second day (Sunday). <br />
            <br />
          </li>
          <li>
            Two booklets mean four hours, three booklets mean six hours, etc.{" "}
            <br />
            <br />
          </li>
          <li>
            All Rally means Saturday till Sunday 5:00PM. Every further detail
            will be mentioned for every online challenge. <br />
            <br />
          </li>
          <li>
            Each team must have an Instagram and TikTok profile. <br />
            <br />
          </li>
          <li>
            Some of the online challenges may require the organizing team to be
            physically present with you. Therefore, the timing may not always be
            crucial, as the presence of the organizing team is what matters.{" "}
            <br />
            <br />
          </li>
          If you think you’re smart, think twice. Show me what you’ve got!{" "}
        </ul>
      ),
    },
    {
      title: "Quest Instructions",
      desc: (
        <ul>
          It's happening – AUB Rally Paper's hardest quests are back! <br />
          <br />
          <li>
            Quests will be released on the website every one and a half hours or
            two hours <br />
            <br />
          </li>
          <li>
            There are three different levels of difficulty: easy, medium, and
            hard, with higher point values awarded for harder quests. <br />
            <br />
          </li>
          <li>
            Each quest must be completed within the indicated time
            <br />
            <br />
          </li>
          <li>
            Once completed, you must contact the designated quest organizer,
            whose contact information will be provided to team leaders.
            <br />
            <br />
          </li>
          <li>
            During the call, you must provide the location answer and explain
            how you arrived at it.
            <br />
            <br />
          </li>
          <li>
            Suggesting a location without any valid explanation will not be
            admissible.
            <br />
            <br />
          </li>
          <li>
            The organizer will respond with either "yes" or "no".
            <br />
            <br />
          </li>
          <li>
            If the answer is "yes", the organizer will provide the exact
            location where challengers can begin the challenge.
            <br />
            <br />
          </li>
          <li>
            If the answer is "no", it means that either the location is
            incorrect, or the explanation provided was insufficient.
            <br />
            <br />
          </li>
          <li>
            For each quest, you are allowed to call the organizer up to three
            times for free.
            <br />
            <br />
          </li>
          <li>
            Starting the 4th call, you will get a 30% deduction on the 4th one
            and a 10% deduction for each subsequent call.
            <br />
            <br />
          </li>
          <li>
            When calling the organizer, you have the option to request hints,
            with expensive hints costing 40%, medium hints costing 30%, and
            cheap hints costing 20%.
            <br />
            <br />
          </li>
          <li>
            Keep in mind that the more expensive hints will provide more
            valuable information to solve a step in the quest.
            <br />
            <br />
          </li>
          <li>
            When calling the organizer for a hint, you will need to inform the
            quest organizer whom you've contacted about your progress in solving
            the quest. They will then provide you with hints corresponding to
            your current level of progress. You can then decide whether you wish
            to accept the hints or continue without them.
            <br />
            <br />
          </li>
          If you think you’re smart, think twice. Show me what you’ve got!{" "}
        </ul>
      ),
    },
    {
      title: "Treasures Instructions",
      desc: (
        <ul>
          It's happening – AUB Rally Paper's hardest treasures are back! <br />
          <br />{" "}
          <li>
            All Day Means till 10:00PM on the first day (Saturday) and 5:00PM on
            the second day (Sunday). <br />
            <br />
          </li>
          <li>
            Two booklets mean four hours, three booklets mean six hours, etc.{" "}
            <br />
            <br />
          </li>
          <li>
            All Rally means Saturday till Sunday 5:00PM. All pictures should
            contain a flyer of your team and “AUB rallypaper 2024 X Suyool”{" "}
            <br />
            <br />
          </li>
          <li>
            All physical treasures should be handled at 1st floor of the Orange
            Building by a member of the team (LOCATION LINK TO ADD) <br />
            <br />
          </li>
          If you think you’re smart, think twice. Show me what you’ve got!{" "}
        </ul>
      ),
    },
    {
      title: "Booklets Instructions",
      desc: (
        <ul>
          It's happening – AUB Rally Paper's famous booklet questions are back!
          You have two hours to complete this booklet and submit it on our
          website through www.rallypaperaub.com.
          <br />
          <br />
          <li>
            Booklets will automatically open for all teams every two hours
            starting Saturday April 20, 8:00 AM, and must be submitted exactly
            two hours later. The booklets are Excel files, with an assigned
            column for answers. The Excel file must be filled with your answers
            and submitted back to us.
            <br />
            <br />
          </li>
          <br />
          <li>
            The deadline for each booklet can be seen on the website (as well as
            on the Excel file) and will not be subject to change. In total,
            teams are required to fill the answers for 12 booklets, spanning
            from Saturday 20 April, 8:00 AM to Sunday 21 April, 6:00 PM.
            <br />
            <br />
          </li>
          <br />
          <li>
            Every booklet contains around 65 questions, divided into three
            sections:
            <ul>
              <li>
                A brainteasers and logic-driven questions section, labelled “L”
                in the “Type” column
                <br />
              </li>
              <li>
                A series of miscellaneous and general knowledge questions,
                labelled “T” in the “Type” column
                <br />
              </li>
              <li>
                A series of questions regarding our sponsors, labelled “S” in
                the “Type” column
                <br />
              </li>
            </ul>
          </li>
          <br />
          <li>
            It is up to each team to decide how to split the work in order to
            fill the booklet as efficiently and effectively as possible.
            <br />
            <br />
          </li>
          <br />
          Grading:
          <br />
          <li>
            The questions of the booklet will account for 100,000 points in
            total. Questions will carry different weights depending on their
            difficulty. A dedicated column labelled “Weight” indicates the
            question’s weight – So allocate your time efficiently!
            <br />
            <br />
          </li>
          <br />
          Good Luck:
          <br />
          <li>
            It is now your time to shine. Good luck in solving the most
            difficult series of questions you have ever faced – We made sure you
            are going to have a hard time!
            <br />
            <br />
          </li>
        </ul>
      ),
    },
    {
      title: "Driving and Savety",
      desc: (
        <ul>
          Safety is our top priority in the AUB Rally Paper. Before anything
          else, we prioritize the well-being of all participants. Every aspect
          of the event is meticulously planned with safety in mind, ensuring
          that each challenge and activity is conducted in a manner that
          minimizes risks and prioritizes the health and security of all
          involved. <br />
          <br />
          <br />
          <h3>Driving:</h3>
          <li>
            We strictly enforce speed limits and adhere to all traffic
            regulations. <br />
            <br />
          </li>
          <li>
            Participants without a driver’s license are prohibited from driving
            during the rally. Previously contacted chauffeurs should be the ones
            taking them from a location to another otherwise. <br />
            <br />
          </li>
          <li>
            Consumption of alcohol is strictly forbidden to ensure the safety of
            all participants on the road. <br />
            <br />
          </li>
          <li>
            Every person should be wearing a seatbelt when the car is moving.{" "}
            <br />
            <br />
          </li>
          <li>
            If the car contains 5 seats with seatbelts, a maximum of 5 people
            can be present in the car of which at least one person is a red
            cross member. <br />
            <br />
          </li>
          <li>
            Each car should have a spare tire and a first aid kit. <br />
            <br />
          </li>
          <li>
            There will be a verification of the cars at each challenge to ensure
            they still have everything. <br />
            <br />
          </li>
          <li>
            There are no points accorded to how fast a team arrives to a
            location. The only points that matter are the points of solving
            rapidly the quest of this location. Once it is solved, the accurate
            location is sent to the challenger’s team and they can take all the
            time they need to arrive to this location. <br />
            <br /> <br />
          </li>
          <h3>Safety at the location:</h3>
          <li>
            First aid kits are stationed at each location throughout the rally
            route to provide immediate medical assistance if needed. <br />
            <br />
          </li>
          <li>
            Organizers are informed of the nearest hospitals in case of
            emergencies. <br />
            <br />
          </li>
          <li>
            Members of the Red Cross rescue teams are part of the organizing
            team and will be present in the challenges locations to provide
            immediate medical assistance if necessary. <br />
            <br />
          </li>
        </ul>
      ),
    },
  ];
  const [openIndex, setOpenIndex] = useState(-1); // State to keep track of which rule's description is open
  const toggleDescription = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };
  return (
    <div className="rulesPage">
      {rules.map((item, index) => (
        <div className="rule" key={index}>
          <div className="ruleTitle" onClick={() => toggleDescription(index)}>
            <div>{item.title}</div>
            <button className="toggleButton">
              {openIndex === index ? "-" : "+"}
            </button>
          </div>
          {openIndex === index && <div className="ruleDesc">{item.desc}</div>}
        </div>
      ))}
    </div>
  );
};

export default RulesPage;
