import React from "react";
import img1 from "../src/Logos/suyool_page-0001.jpg";
import img2 from "../src/Logos/&i logo .png";
import img3 from "../src/Logos/Wynk-Logo.jpg";
import img4 from "../src/Logos/coral_page-0001.jpg";
import img5 from "../src/Logos/crepaway.jpg";
import img6 from "../src/Logos/factpeople.jpg";
import img7 from "../src/Logos/icetea.jpg";
import img8 from "../src/Logos/makbar.jpg";
import img9 from "../src/Logos/simly - black.png";
import img10 from "../src/Logos/tickit.jpg";
import img11 from "../src/Logos/wakilni-logo-v2_480x480.jpg";
const Sponsors = () => {
  // Assuming you have an array of sponsor logos
  const sponsors = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
  ];

  return (
    <div className="sponsors-container">
      <div className={`sponsor sponsorBig`}>
        <img className="sponsorImg" src={img1} alt={`Sponsor`} />
      </div>
      {sponsors.map((sponsor) => (
        <div className={`sponsor`}>
          <img className="sponsorImg" src={sponsor} alt={`Sponsor`} />
        </div>
      ))}
    </div>
  );
};

export default Sponsors;
